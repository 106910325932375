<template>
  <v-container>
    <v-card
      max-width="800"
      class="charcoal charcoalTile mx-auto d-flex flex-column mt-5 pb-5 silver--text flat"
    >
      <v-form ref="registerForm">
        <v-card-title class="paper--text display-2 pa-0 pl-2 pt-2">
          Register
          <v-spacer />
          <BaseActionButton
            label="Or Login"
            plain
            small
            color="silver"
            icon="mdi-login-variant"
            @clickedThis="$router.push('/login')"
          />
        </v-card-title>
        <v-card-actions class="py-0">
          <v-spacer />
          <v-switch
            dark
            v-model="entername"
            color="progressActive"
            class="pa-0 mb-n4"
            label="Enter Name?"
          >
            <template v-slot:label>
              <span class="silver--text">Enter Name?</span>
            </template>
          </v-switch>
        </v-card-actions>
        <v-sheet rounded class="charcoal charcoalTileMenu pt-4 px-2 mx-2">
          <template v-if="entername">
            <v-text-field
              :error-messages="firstNameErrors"
              label="First Name"
              ref="fname"
              color="progressActive"
              background-color="paper"
              filled
              clearable
              single-line
              v-model="fname"
              :counter="50"
              prepend-inner-icon="mdi-account-circle"
              @input="$v.fname.$touch()"
              @blur="fname = capitalize(fname)"
            />
            <v-text-field
              :error-messages="lastNameErrors"
              label="Last Name"
              name="lname"
              color="progressActive"
              background-color="paper"
              filled
              clearable
              single-line
              v-model="lname"
              prepend-inner-icon="mdi-account-circle"
              @input="$v.lname.$touch()"
              @blur="lname = capitalize(lname)"
            />
          </template>
          <template v-else>
            <span class="caption silver--text" v-if="token == null">
              We've selected a random name for you. You can change by
              {{ isPhone ? 'tapping' : 'clicking' }} "Enter Name" above.
            </span>
            <v-spacer />
            <v-card-text :class="textSize + ' pa-0 my-1 silver--text'">
              {{ fname }} {{ lname }}
            </v-card-text>
          </template>
          <v-text-field
            :error-messages="emailErrors"
            label="Email"
            color="progressActive"
            background-color="paper"
            filled
            autofocus
            clearable
            single-line
            type="email"
            name="email"
            v-model="email"
            required
            prepend-inner-icon="mdi-email"
            @input="$v.email.$touch()"
          />
          <v-text-field
            :error-messages="passwordErrors"
            :type="showPassword ? 'text' : 'password'"
            name="password"
            label="Password"
            color="progressActive"
            background-color="paper"
            filled
            clearable
            single-line
            v-model="password"
            prepend-inner-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            @input="$v.password.$touch()"
          />
          <v-text-field
            :error-messages="repeatPasswordErrors"
            :type="showPassword ? 'text' : 'password'"
            name="password2"
            label="Repeat Password"
            v-model="password2"
            color="progressActive"
            background-color="paper"
            filled
            clearable
            single-line
            prepend-inner-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            @input="$v.password2.$touch()"
          />

          <v-card-actions class="pb-0 pl-0 caption">
            <a
              title="Terms & Conditions"
              class="pr-2  mt-n7 silver--text"
              href="/termsandconditions"
            >
              Terms & Conditions
            </a>
            <v-spacer />
            <v-switch
              dark
              v-model="agree"
              color="progressActive"
              label="Agree?"
              class="mt-n2"
            >
              <template v-slot:label>
                <span class="silver--text">Agree?</span>
              </template>
            </v-switch>
          </v-card-actions>
        </v-sheet>
        <v-spacer />
        <v-card-actions class="pb-0 mt-2">
          <v-spacer />

          <BaseActionButton
            :disabled="this.$v.$anyError || email == '' || !agree"
            label="Register"
            title="Register New Account"
            text
            dark
            class="mr-n charcoalTileMenu"
            icon="mdi-account-plus"
            color="progressActive"
            @clickedThis="register()"
          />
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
  required,
  maxLength,
  minLength,
  sameAs,
  email
} from 'vuelidate/lib/validators'
import { isPasswordValid } from '@/validators'
import util from '@/mixins/util.js'
import { appConfig } from '@/store/helpers.js'
import axios from 'axios'

export default {
  name: 'RegisterComponent',
  mixins: [validationMixin, util],
  components: {},
  data: () => ({
    birthday: '',
    showPassword: false,
    fname: '',
    lname: '',
    email: '',
    password: '',
    password2: '',
    agree: false,
    entername: false,
    timeout: 1000,
    invite: {},
    sitekey: '6LcGA6saAAAAAEJ6y4eVkseDEz-uTdDVFhQ0ToUp'
  }),
  beforeMount() {
    if (this.token == null) this.loadName()
    else this.loadInvite()
  },
  mounted() {},
  validations: {
    fname: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(50)
    },
    lname: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(50)
    },
    email: { required, email },
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(250),
      isPasswordValid: isPasswordValid
    },
    password2: {
      sameAsPassword: sameAs('password')
    }
  },
  computed: {
    firstNameErrors() {
      const errors = []
      if (!this.$v.fname.$dirty) return errors
      !this.$v.fname.maxLength &&
        errors.push('First name must be at most 50 characters long')
      !this.$v.fname.required && errors.push('First name is required.')
      return errors
    },
    lastNameErrors() {
      const errors = []
      if (!this.$v.lname.$dirty) return errors
      !this.$v.lname.maxLength &&
        errors.push('Last name must be at most 50 characters long.')
      !this.$v.lname.required && errors.push('Last name is required.')
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Invalid e-mail.')
      !this.$v.email.required && errors.push('E-mail is required.')
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('Password is required.')
      !this.$v.password.isPasswordValid &&
        errors.push(
          'Invalid password. Password must be: minimum 8 characters long, maximum 250 long and contain at least one uppercase letter, one lowercase letter and one number.'
        )
      return errors
    },
    repeatPasswordErrors() {
      const errors = []
      if (!this.$v.password2.$dirty) return errors
      !this.$v.password2.sameAsPassword &&
        errors.push('Password and repeat password should match.')
      return errors
    },
    ...appConfig
  },

  props: {
    token: {
      type: String,
      default: null
    }
  },
  methods: {
    resetForm() {
      this.$refs.registerForm.reset()
      this.$v.$reset()
      this.$refs.fname.focus()
    },
    loadInvite() {
      return axios
        .get(this.baseURL + '/invites/accept/' + this.token, {})
        .then(response => {
          if (response.status == 200) {
            this.invite = response.data.data
            if (!this.isEmpty(this.invite)) {
              const [first, last] = this.invite.name.split(' ')
              this.fname = first
              this.lname = last
              this.email = this.invite.email
            }
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadName() {
      return axios
        .get(this.baseURL + '/user/public/register/names', {})
        .then(response => {
          if (response.status == 200) {
            this.name = response.data.data
            this.fname = this.name.fname
            this.lname = this.name.lname
            if (this.token != null) this.loadInvite()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    acceptInvite() {
      return axios
        .put(this.baseURL + '/invites/accept', {
          token: this.token
        })
        .then(response => {
          if (response.status == 200) {
            null
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              {
                root: true
              }
            )
            return
          }
        })
    },
    register() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      } else {
        return axios
          .post(this.baseURL + '/accounts/register', {
            email: this.email,
            password: this.password,
            confpassword: this.password2,
            lname: this.lname,
            fname: this.fname,
            token: '',
            token_expiry: ''
          })
          .then(response => {
            if (response.status == 200) {
              this.toast('Registration complete!! Please login.')
              if (!this.isEmpty(this.invite)) {
                this.acceptInvite()
              }
              this.$router.push({ name: 'login' })
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    }
  }
}
</script>
