// passwordValidators.js
const passwordValidator = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,250}$/
const zipValidator = /^\d{5}(?:[-\s]\d{4})?$/

export function isPasswordValid(value) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  }
  return passwordValidator.test(value)
}

export function isValidZip(value) {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  }
  return zipValidator.test(value)
}
